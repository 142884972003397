import { AvsConfig } from './config/avs-config.model';
import { AvsConversion } from './status/avs-conversion.model';
import { MediaInfo } from './media-info.model';

/**
 * Models of the AVS module
 */
export const avsModels =
  [
    AvsConfig,
    AvsConversion,
    MediaInfo,
  ];
