<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    <ds-metadata-values [mdValues]="object?.allMetadata(['dc.title'])"></ds-metadata-values>
  </h2>
  <div class="pl-2">
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'item.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <atmire-avs-player [item]="object"></atmire-avs-player>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper (click)="open(content)">
      <ds-thumbnail [thumbnail]="getThumbnail() | async"></ds-thumbnail>
    </ds-metadata-field-wrapper>
      <div *ngIf="hasManifest$.getValue()">
        <button style="margin-bottom: 5px;" class="btn btn-outline-primary" (click)="open(content)">Launch IIIF Viewer</button>
      </div>
    <ds-item-page-file-section [item]="object"></ds-item-page-file-section>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-metadata-representation-list
      [parentItem]="object"
      [itemType]="'Person'"
      [metadataField]="'dc.contributor.author'"
      [label]="'relationships.isAuthorOf' | translate">
    </ds-metadata-representation-list>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'exhibitOfItem'"
      [label]="'Exhibits'">
    </ds-related-items>
  </div>
</div>
<div class="row pageContianer">
  <table class="table table-responsive table-striped">
    <tbody>
    <tr *ngFor="let key of metadata.keys()">
      <td>{{key}}</td>
      <td>{{metadata.get(key).value}}</td>
      <td>{{metadata.get(key).language}}</td>
    </tr>
    </tbody>
  </table>
  <div>
    <a class="btn btn-outline-primary" [routerLink]="['/items/' + object.id + '/full']">
      {{"item.page.link.full" | translate}}
    </a>
  </div>
</div>
<ng-template #content let-modal>
  <div class="modal-header" >
    <h4 class="modal-title" id="modal-basic-title">IIIF Preview</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ds-mirador
      class="mirador-container"
      [uuid]="this.uuid">
    </ds-mirador>
  </div>
  <div class="modal-footer">
  </div>
</ng-template>
