import {Component, Input, OnInit} from '@angular/core';
import {BatchObject} from '../../../core/shared/batch-object.model';
import {BatchObjectService} from '../../../core/data/batch-object.service';

@Component({
  selector: 'ds-batch-detail-links',
  templateUrl: './batch-detail-links.component.html'
})
/**
 * Component representing list element for a batch
 */
export class BatchDetailLinks implements OnInit {

  @Input()
  public batch: BatchObject;

  public arrayOfKeys: string[];

  public phaseFiles:Map<string, Array<object>> = new Map<string,  Array<object>>();

  public phaseCompletedMap:Map<string, string> = new Map<string, string>();

  public objectMap;

  public phaseKeyArray:string[] = new Array();

  public phaseArray:string[] = ["Scan", "Expand", "Validate", "Import", "Load", "Derive", "Submit", "Generate", "Default", "Restore"];

  constructor(private batchObjectService: BatchObjectService) {
  }

  ngOnInit(): void {
    this.objectMap = new Map(Object.entries(this.batch.file));
    this.arrayOfKeys = Object.keys(this.batch.file);
    for (let i = 0; i < this.arrayOfKeys.length; i++) {
      if (this.objectMap.get(this.arrayOfKeys[i])[0] != null && this.objectMap.get(this.arrayOfKeys[i])[0].path != null) {
        for (let y = 0; y < this.objectMap.get(this.arrayOfKeys[i]).length; y++) {
          if (this.arrayOfKeys[i] != "input") {
            if (this.objectMap.get(this.arrayOfKeys[i])[y].size > 0 || this.objectMap.get(this.arrayOfKeys[i])[y].label == 'Completed') {
              let files: Array<object> = new Array<object>();
              files.push(this.objectMap.get(this.arrayOfKeys[i])[y]);
              let pathSplit = this.objectMap.get(this.arrayOfKeys[i])[y].path.split('/');
              if (this.hasPhaseInFilePath(pathSplit[1], this.objectMap.get(this.arrayOfKeys[i])[y].path) && this.phaseArray.includes(pathSplit[1])) {
                if (this.phaseFiles.get(pathSplit[1]) == null) {
                  this.phaseFiles.set(pathSplit[1], files);
                } else {
                  let additive = this.phaseFiles.get(pathSplit[1]);
                  additive.push(this.objectMap.get(this.arrayOfKeys[i])[y]);
                  this.phaseFiles.set(pathSplit[1], additive);
                }
              }
            }
          }
        }
      }
    }
    // Reverse map order so when listed out it's in chronological order
    if (this.phaseFiles.size > 0) {
      for (let phaseWithKey of this.phaseArray) {
        if (this.phaseFiles.get(phaseWithKey) != null) {
          this.phaseKeyArray.push(phaseWithKey);
        }
      }
      for(let phase of this.phaseKeyArray) {
        for(let object of this.phaseFiles.get(phase)) {
          if (object['path'] != null && object['label'] == 'Completed') {
            this.phaseCompletedMap.set(phase, object['lastModified'])
          }
        }
      }
    }
  }

  hasPhaseInFilePath(phaseString: string, filePath: string) {
    if (filePath.indexOf(phaseString) != -1) {
      return true;
    }
    return false;
  }

  doesCurrentPhaseHaveOutputs(phaseString: string) {
    if (this.phaseFiles.get(phaseString) != null) {
      return true;
    }
    return false;
  }

  isCsv(label:string){
    if (label.includes(".csv")) {
      return true;
    } else {
      return false;
    }
  }

  isSaveResult(phaseString: string) {
    if (phaseString == 'SaveResult') {
      return true;
    }
    return false;
  }
}
