import {DataService} from "./data.service";
import {HALEndpointService} from "../shared/hal-endpoint.service";
import {HttpClient} from "@angular/common/http";
import {NotificationsService} from "../../shared/notifications/notifications.service";
import {RemoteDataBuildService} from "../cache/builders/remote-data-build.service";
import {CoreState} from "../core.reducers";
import {Store} from "@ngrx/store";
import {RequestService} from "./request.service";
import {ObjectCacheService} from "../cache/object-cache.service";
import {DefaultChangeAnalyzer} from "./default-change-analyzer.service";
import {Observable} from "rxjs/internal/Observable";
import {dataService} from "../cache/builders/build-decorators";
import {RTS_VERSION_OBJECT} from "../shared/rts-version-object.resource-type";
import {Injectable} from "@angular/core";
import {RTSVersionObject} from "../shared/rts-version-object-model";

/**
 * Service handling all RTS version object requests
 */
@Injectable({
  providedIn: 'root'
})
@dataService(RTS_VERSION_OBJECT)
export class RTSVersionObjectDataService extends DataService<RTSVersionObject> {
  protected linkPath: string = 'rts-versions';

  constructor(protected requestService: RequestService,
              protected rdbService: RemoteDataBuildService,
              protected store: Store<CoreState>,
              protected halService: HALEndpointService,
              protected objectCache: ObjectCacheService,
              protected notificationsService: NotificationsService,
              protected http: HttpClient,
              protected comparator: DefaultChangeAnalyzer<RTSVersionObject>) {
    super();
  }

  getBrowseEndpoint(options, linkPath?: string): Observable<string> {
    return this.halService.getEndpoint(this.linkPath);
  }
}
