<div *ngFor="let key of keysWithPath" class="actions">
  <a *ngIf="key == 'log'" href="{{this.fileMap.get(key)}}" target="_blank"><button class="btn btn-secondary">View Log</button></a>
</div>
<div style="display: flex;" class="actions" *ngFor="let key of keysWithPathInArray">
  <button class="btn btn-secondary" *ngIf="this.pathMap.get(key).toLowerCase().indexOf('uuid-list.txt') > -1" (click)="createExport(this.batch.id, this.pathMap.get(key))">Create export</button>
</div>
<div style="display: flex;" class="actions">
  <span *ngFor="let value of this.fileMapInArray.values()">
    <a href="javascript:void(0)" *ngIf="value.indexOf('New-uuid-list.txt') > -1" (click)="myDspaceLinkClick(this.batch.id)"><button class="btn btn-secondary">View in My DSpace</button></a>
  </span>
</div>
