import {autoserialize, autoserializeAs, deserialize} from 'cerialize';
import { ListableObject } from '../../shared/object-collection/shared/listable-object.model';
import {link, typedObject} from '../cache/builders/build-decorators';
import {CacheableObject} from '../cache/object-cache.reducer';
import { GenericConstructor } from './generic-constructor';
import { HALLink } from './hal-link.model';
import {excludeFromEquals} from '../utilities/equals.decorators';
import {ResourceType} from './resource-type';
import {RTS_VERSION_OBJECT} from "./rts-version-object.resource-type";
import {Observable} from "rxjs";
import {RemoteData} from "../data/remote-data";
import {EPERSON} from "../eperson/models/eperson.resource-type";
import {EPerson} from "../eperson/models/eperson.model";
import {ManifestObject} from "./manifest-object.model";
import {MANIFEST_OBJECT} from  "./manifest-object-reource-type"

/**
 * An abstract model class for a Batch Object.
 */
@typedObject
export class RTSVersionObject extends ListableObject implements CacheableObject {

  static type = RTS_VERSION_OBJECT;
  /**
   * A string representing the RTSStatusObject
   */
  @excludeFromEquals
  @autoserialize
  type: ResourceType;
  @autoserialize
  public id;
  @excludeFromEquals
  @autoserialize
  public dsoType: ResourceType;
  @autoserialize
  public archivedDate: string;
  @autoserializeAs(String)
  public modifiedDate: string;
  @deserialize
  _links: {
    self: HALLink;
    auditRecord: HALLink;
    manifest: HALLink;
    modifier: HALLink;
    status: HALLink;
  };

  @link(EPERSON)
  modifier?: Observable<RemoteData<EPerson>>;

  @link(MANIFEST_OBJECT)
  manifest?: Observable<RemoteData<ManifestObject>>;

  /**
   * A shorthand to get this batch's self link
   */
  get self(): string {
    return this._links.self.href;
  }

  /**
   * A shorthand to set this DSpaceObject's self link
   */
  set self(v: string) {
    this._links.self = {
      href: v
    };
  }

  /**
   * Method that returns as which type of object this object should be rendered
   */
  getRenderTypes(): Array<string | GenericConstructor<ListableObject>> {
    return [this.constructor as GenericConstructor<ListableObject>];
  }
}


