<div class="item" [id]="dso.id" *ngIf="object !== undefined && object !== null">
  <a *ngIf="linkType != linkTypes.None"
     [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer"
     [routerLink]="['/items/' + dso.id]" class="lead">
    <ds-thumbnail [thumbnail]="getThumbnail(dso) | async"></ds-thumbnail>
  </a>
  <ds-thumbnail *ngIf="linkType == linkTypes.None" [thumbnail]="getThumbnail(dso) | async"></ds-thumbnail>
  <div class="data">
    <div class="ListItemTitleBadge">
      <ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>
      <a *ngIf="linkType != linkTypes.None"
         [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer"
         [routerLink]="['/items/' + dso.id]" class="lead"
         [innerHTML]="firstMetadataValue('dc.title')"></a>
      <span *ngIf="linkType == linkTypes.None" class="lead"
            [innerHTML]="firstMetadataValue('dc.title')"></span>
    </div>
    <div *ngIf="isRelationship()" class="resultParentExhibit">
      <ds-related-items
        [parentItem]="dso"
        [relationType]="'parentOfExhibit'"
        [label]=""
        [incrementBy]="1">
      </ds-related-items>
      <ds-truncatable [id]="dso.id" class="text-muted">
        <ds-truncatable-part [id]="dso.id" [minLines]="1" class="author">
          <ng-container
            *ngIf="dso.firstMetadataValue('dc.publisher') || dso.firstMetadataValue('dc.date.issued')">(<span
            class="item-list-publisher"
            [innerHTML]="firstMetadataValue('dc.publisher')">, </span><span
            *ngIf="dso.firstMetadataValue('dc.date.issued')" class="item-list-date"
            [innerHTML]="firstMetadataValue('dc.date.issued')"></span>)</ng-container>
          <span *ngIf="dso.allMetadata(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']).length > 0"
                class="item-list-authors">
                <span
                  *ngFor="let author of allMetadataValues(['dc.contributor.author', 'dc.creator', 'dc.contributor.*']); let last=last;">
                    <span [innerHTML]="author"><span [innerHTML]="author"></span></span>
                    <span *ngIf="!last">; </span>
                </span>
        </span>
        </ds-truncatable-part>
      </ds-truncatable>
    </div>
  </div>
</div>
<div>
  <ds-truncatable [id]="dso.id" *ngIf="dso.firstMetadataValue('dc.description.abstract')" class="item-list-abstract">
    <ds-truncatable-part [id]="dso.id" [minLines]="3"><span
      [innerHTML]="firstMetadataValue('dc.description.abstract')"></span>
    </ds-truncatable-part>
  </ds-truncatable>
</div>
