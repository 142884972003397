import { Component } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ds-bug-report',
  styleUrls: ['bug-report.component.scss'],
  templateUrl: 'bug-report.component.html',
})

export class BugReportComponent {

  constructor(private modalService: NgbModal) {
  }

  open(content) {
    this.modalService.open(content, {size: 'lg', windowClass: 'modal-xl'});
  }

}
