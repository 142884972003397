<div class="outputs">
  <div *ngFor="let key of keysWithPath">
    <a  href="{{this.fileMap.get(key)}}" target="_blank">{{this.objectMap.get(key).label}}</a>
    <br/>
    <a  href="javascript:void(0)" (click)="myDspaceLinkClick(this.batch.id)">View in My DSpace</a>
  </div>
  <div *ngFor="let key of keysWithPathInArray">
      <a  href="{{this.fileMapInArray.get(key)}}" target="_blank">{{this.nameMapForArray.get(key)}}</a>
      <button class="btn btn-sm btn-primary mt-1" *ngIf="this.pathMap.get(key).toLowerCase().indexOf('uuid-list.txt') > -1" (click)="createExport(this.batch.id, this.pathMap.get(key))">Create export</button>
  </div>
</div>
