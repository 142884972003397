import {environment} from '../../../environments/environment';
import { FollowLinkConfig } from '../../shared/utils/follow-link-config.model';
import {dataService} from '../cache/builders/build-decorators';
import {BATCH_OBJECT} from '../shared/batch-object.resource-type';
import { DataService } from './data.service';
import { RequestService } from './request.service';
import { RemoteDataBuildService } from '../cache/builders/remote-data-build.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../core.reducers';
import { HALEndpointService } from '../shared/hal-endpoint.service';
import { ObjectCacheService } from '../cache/object-cache.service';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { DefaultChangeAnalyzer } from './default-change-analyzer.service';
import {Injectable} from '@angular/core';
import { GetRequest } from './request.models';
import { Observable } from 'rxjs/internal/Observable';
import {switchMap, take} from 'rxjs/operators';
import { RemoteData } from './remote-data';
import {PaginatedList} from './paginated-list.model';
import {BatchObject} from '../shared/batch-object.model';
import {RestRequestMethod} from './rest-request-method';
import {TranslateService} from '@ngx-translate/core';
import {DspaceRestService} from '../dspace-rest/dspace-rest.service';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';

/**
 * Service handling all BatchObject requests
 */
@Injectable()
@dataService(BATCH_OBJECT)
export class BatchObjectService extends DataService<BatchObject> {
  // Observable string sources
  private componentMethodCallSource = new Subject<any>();

  // Observable string streams
  componentMethodCalled$ = this.componentMethodCallSource.asObservable();

  protected linkPath = 'batchtasks';

  constructor(protected requestService: RequestService,
              protected rdbService: RemoteDataBuildService,
              protected store: Store<CoreState>,
              protected halService: HALEndpointService,
              protected objectCache: ObjectCacheService,
              protected notificationsService: NotificationsService,
              protected http: HttpClient,
              protected comparator: DefaultChangeAnalyzer<BatchObject>,
              private translate: TranslateService,
              private restService: DspaceRestService,
              private router: Router) {
    super();
  }

  getBrowseEndpoint(options, linkPath?: string): Observable<string> {
    return this.halService.getEndpoint(this.linkPath);
  }


  getBatchEndpoint(batchId): Observable<string> {
    return this.halService.getEndpoint(this.linkPath).pipe(
      switchMap((href) => this.halService.getEndpoint('batchtasks', `${href}/${batchId}`))
    );
  }

  makeNewBatch() {
    this.componentMethodCallSource.next();
  }

  /**
   * Get the allowed relationship types for an entity type
   * @param entityTypeId
   * @param linksToFollow     List of {@link FollowLinkConfig} that indicate which {@link HALLink}s should be automatically resolved
   */
  getBatches(batchId, ...linksToFollow: FollowLinkConfig<BatchObject>[]): Observable<RemoteData<PaginatedList<BatchObject>>> {

    const href$ = this.getBatchEndpoint(batchId);

    href$.pipe(take(1)).subscribe((href) => {
      const request = new GetRequest(this.requestService.generateRequestId(), href);
      this.requestService.send(request);
    });

    return this.rdbService.buildList(href$, ...linksToFollow);
  }

  doAction(action, batchID) {
    const headersObject = new HttpHeaders();
    headersObject.append('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
      headers: headersObject
    };
    const url = environment.rest.baseUrl + '/api/core/batchtasks/executeAction?id=' + batchID + '&action=' + action;
    this.restService.request(RestRequestMethod.POST, url, null, httpOptions)
      .subscribe((data) => {
        if (data.statusCode === 200) {
          this.componentMethodCallSource.next(batchID);
          this.notificationsService.success(null, action.toString() + ' action success!');
        } else {
          this.notificationsService.error(null, action.toString() + + ' action failed!');
        }
      });
  }

  doCSVExport(batchID, filePath) {
    const headersObject = new HttpHeaders();
    const inputFromBatchObject = { 'inputFromBatch': {'id': batchID, 'path': filePath}};
    headersObject.append('Content-Type', 'application/x-www-form-urlencoded');
    const httpOptions = {
      headers: headersObject
    };
    const url = environment.rest.baseUrl + '/api/core/batchtasks/create?type=csv-export';
    this.restService.request(RestRequestMethod.POST, url, inputFromBatchObject, httpOptions)
      .subscribe((data) => {
        if (data.statusCode === 200) {
          this.makeNewBatch();
          this.notificationsService.success(null, 'Create export success');
          if (data.payload != null && data.payload.batchId) {
            this.router.navigateByUrl('/batch/' + data.payload.batchId);
          }
        } else {
          this.notificationsService.error(null, 'Create export error');
        }
      });
  }
}
