import { typedObject } from '../../core/cache/builders/build-decorators';
import { CacheableObject } from '../../core/cache/object-cache.reducer';
import { AVS_CONVERSION } from './avs-conversion.resource-type';
import { autoserialize, deserialize } from 'cerialize';
import { HALLink } from '../../core/shared/hal-link.model';
import { excludeFromEquals } from '../../core/utilities/equals.decorators';
import { ResourceType } from '../../core/shared/resource-type';

/**
 * The AVS conversion status of a bitstream
 */
@typedObject
export class AvsConversion implements CacheableObject {
  static type = AVS_CONVERSION;

  @autoserialize
  status: string;

  @autoserialize
  info: string;

  @autoserialize
  forceButton: boolean;

  @deserialize
  _links: { self: HALLink };

  @excludeFromEquals
  @autoserialize
  type: ResourceType;
}
