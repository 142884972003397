<ng-template #bitstreamView>
  <div class="{{columnSizes.columns[0].buildClasses()}} row-element d-flex">
    <ng-content select="[slot=drag-handle]"></ng-content>
    <div class="float-left d-flex align-items-center">
      {{ bitstreamName }}
    </div>
  </div>
  <div class="{{columnSizes.columns[1].buildClasses()}} row-element d-flex align-items-center">
    <div class="w-100">
      {{ bitstream?.firstMetadataValue('dc.description') }}
    </div>
  </div>
  <div class="{{columnSizes.columns[2].buildClasses()}} row-element d-flex align-items-center">
    <div class="text-center w-100">
      {{ (format$ | async)?.shortDescription }}
    </div>
  </div>
  <div class="{{columnSizes.columns[3].buildClasses()}} row-element d-flex align-items-center">
    <div class="text-center w-100">
      <div class="btn-group relationship-action-buttons">
        <a [href]="bitstream?._links?.content?.href"
                class="btn btn-outline-primary btn-sm"
                title="{{'item.edit.bitstreams.edit.buttons.download' | translate}}">
          <i class="fas fa-download fa-fw"></i>
        </a>
        <button [routerLink]="['/bitstreams/', bitstream.id, 'edit']" class="btn btn-outline-primary btn-sm"
                title="{{'item.edit.bitstreams.edit.buttons.edit' | translate}}">
          <i class="fas fa-edit fa-fw"></i>
        </button>
        <button [disabled]="!canRemove()" (click)="remove()"
                class="btn btn-outline-danger btn-sm"
                title="{{'item.edit.bitstreams.edit.buttons.remove' | translate}}">
          <i class="fas fa-trash-alt fa-fw"></i>
        </button>
        <button [disabled]="!canUndo()" (click)="undo()"
                class="btn btn-outline-warning btn-sm"
                title="{{'item.edit.bitstreams.edit.buttons.undo' | translate}}">
          <i class="fas fa-undo-alt fa-fw"></i>
        </button>
      </div>
    </div>
  </div>
</ng-template>
