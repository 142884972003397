<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    <ds-metadata-values [mdValues]="object?.allMetadata(['dc.title'])"></ds-metadata-values>
  </h2>
  <div class="pl-2">
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'item.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row pageContianer">
  <div class="col-12">
    <atmire-avs-player [item]="object"></atmire-avs-player>
  </div>
</div>
<div class="row pageContianer" id="thumbContainer">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper>
      <ds-thumbnail [thumbnail]="getThumbnail() | async"></ds-thumbnail>
    </ds-metadata-field-wrapper>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-related-items
      [parentItem]="object"
      [relationType]="'parentOfExhibit'"
      [label]="'Exhibits'">
    </ds-related-items>
    <ng-container *ngFor="let key of fields">
      <ds-generic-item-page-field class="item-page-fields" [item]="object"
                                  [fields]="key"
                                  [label]="'item.preview.' + key">
      </ds-generic-item-page-field>
    </ng-container>
    <ds-item-page-abstract-field [item]="object"></ds-item-page-abstract-field>
  </div>
</div>
<div class="row pageContianerButton">
  <a class="btn btn-outline-primary" [routerLink]="['/items/' + object.id + '/full']">
    {{"item.page.link.full" | translate}}
  </a>
</div>
<div>
  <div class="mt-5 w-100">
    <ds-tabbed-related-entities-search [item]="object"
                                       [relationTypes]="[
                                {
                                  label: 'exhibitOfItem',
                                  filter: 'anyExhibitOfItem',
                                  configuration: 'compoundItemExhibitView'
                                },
                                {
                                  label: 'parentOfExhibit',
                                  filter: 'anyParentOfExhibit',
                                  configuration: 'exhibitTabbedView'
                                }]">
    </ds-tabbed-related-entities-search>
  </div>
</div>



