import {Component, Input, OnInit} from '@angular/core';
import {BatchObject} from '../../../core/shared/batch-object.model';
import {Router} from '@angular/router';
import {BatchObjectService} from '../../../core/data/batch-object.service';

@Component({
  selector: 'ds-batch-create-batch-detail-buttons',
  styleUrls: ['./create-batch-detail-buttons.component.scss'],
  templateUrl: './create-batch-detail-buttons.component.html'
})
/**
 * Component representing list element for a batch
 */
export class CreateBatchDetailButtonsComponent implements OnInit {

  @Input()
  public batch: BatchObject;

  public arrayOfKeys: string[];

  public keysWithPath: string[] = new Array<string>();

  public keysWithPathInArray: string[] = new Array<string>();

  public fileMapInArray: Map<string, string> = new Map<string, string>();

  public nameMapForArray: Map<string, string> = new Map<string, string>();

  public fileMap: Map<string, string> = new Map<string, string>();

  public pathMap: Map<string, string> = new Map<string, string>();

  public objectMap;

  constructor(private batchObjectService: BatchObjectService, private router: Router) {
  }

  ngOnInit(): void {
    this.objectMap = new Map(Object.entries(this.batch.file));
    this.arrayOfKeys = Object.keys(this.batch.file);
    for (const key of this.arrayOfKeys) {
      if (this.objectMap.get(key).path != null) {
        this.keysWithPath.push(key);
        this.fileMap.set(key, this.batch._links.self.href + '/file/' + this.objectMap.get(key).path);
      }
      if (this.objectMap.get(key)[0] != null && this.objectMap.get(key)[0].path != null) {
        for (let y = 0; y < this.objectMap.get(key).length; y++) {
          if (key !== 'input') {
            if (this.objectMap.get(key)[y].size > 0) {
              this.keysWithPathInArray.push(key + y);
              this.fileMapInArray.set(key + y, this.batch._links.self.href + '/file/' + this.objectMap.get(key)[y].path);
              this.pathMap.set(key + y, this.objectMap.get(key)[y].path);
              this.nameMapForArray.set(key + y, this.objectMap.get(key)[y].label);
            }
          }
        }
      }
    }
  }

  createExport(batchID, filePath) {
    this.batchObjectService.doCSVExport(batchID, filePath);
  }

  myDspaceLinkClick(batchId) {
    this.router.navigateByUrl('mydspace?configuration=workspace&f.batchId=' + batchId + '&page=1');
  }
}
