import {Component, Input, OnInit} from '@angular/core';
import mirador from 'mirador';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'ds-mirador',
  styleUrls: ['./mirador.component.scss'],
  templateUrl: './mirador.component.html'
})

export class MiradorComponent implements OnInit {
  @Input()
  uuid: string;

  ngOnInit() {
    let cssExtra = '<style>' +
      'button[aria-label="Workspace options"] {' +
      '  display: none;' +
      '}' +
      'button[aria-label="Workspace settings"] {' +
      '  display: none;' +
      '}' +
      'button[aria-label="Jump to window"] {' +
      '  display: none;' +
      '}' +
      'button[aria-label="Add resource"] {' +
      '  display: none;' +
      '}' +
      '</style>';
    const iframe = document.getElementById('mirador-iframe');
    const iframedoc = (iframe as HTMLIFrameElement).contentDocument || (iframe as HTMLIFrameElement).contentWindow.document;
    const manifestURL = '"' + environment.rest.baseUrl.replace('/api', '') + '/iiif/presentation/' + this.uuid + '/manifest"';
    iframedoc.open();
    iframedoc.write(cssExtra);
    iframedoc.write('\<div id="mirador">\<\/div>');
    iframedoc.write('\<script src="/assets/images/mirador.min.js">\<\/script>');
    iframedoc.write('\<script type="text/javascript">Mirador.viewer({' +
      'id: "mirador", ' +
      'windows: [{' +
          'manifestId: ' + manifestURL + '}],' +
      'window: {allowClose: false,\n' +
      '        allowMaximize: false,\n' +
      '        defaultSideBarPanel: \'annotations\',\n' +
      '        defaultView: \'gallery\',\n' +
      '        sideBarOpenByDefault: true,\n' +
      '      },thumbnailNavigation: {\n' +
      '        defaultPosition: \'off\',\n' +
      '      },workspace: {\n' +
      '        type: \'mosaic\', // Which workspace type to load by default. Other possible values are "elastic"\n' +
      '      },workspaceControlPanel: {' +
      '        enabled: true,' +
      '      },});' +
      '\<\/script>');
    iframedoc.close();
  }
}
