<div class="navbar-nav">
  <a role="button" class="px-1" (click)="open(reportbug)">
    <i class="fas fa-bug fa-lg fa-fw" [title]="'nav.bugReport' | translate"></i>
  </a>
</div>

<ng-template #reportbug let-modal>
  <div class="modal-header" >
    <h4 class="modal-title" id="modal-basic-title">Report Bug</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <iframe class="clickup-embed clickup-dynamic-height" src="https://forms.clickup.com/f/85bpc-973/J41AS5735N0UC71YU6" onwheel="" width="100%" height="100%" style="background: transparent; border: 1px solid #ccc;"></iframe><script async src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"></script>
  </div>
  <div class="modal-footer">
  </div>
</ng-template>
