<div class="container" *ngIf="(isXsOrSm$ | async)">
    <div class="row">
        <div class="col-12">
            <ng-template *ngTemplateOutlet="searchForm"></ng-template>
        </div>
    </div>
</div>
<ds-page-with-sidebar [id]="'search-page'" [sidebarContent]="sidebarContent">
    <div class="row">
        <div class="col-12" *ngIf="!(isXsOrSm$ | async)">
            <ng-template *ngTemplateOutlet="searchForm"></ng-template>
        </div>
        <div id="search-content" class="col-12">
            <div class="d-block d-md-none search-controls clearfix">
                <ds-view-mode-switch [inPlaceSearch]="inPlaceSearch"></ds-view-mode-switch>
                <button (click)="openSidebar()" aria-controls="#search-body"
                        class="btn btn-outline-primary float-right open-sidebar"><i
                        class="fas fa-sliders"></i> {{"search.sidebar.open"
                        | translate}}
                </button>
            </div>
            <ds-search-results [searchResults]="resultsRD$ | async"
                               [searchConfig]="searchOptions$ | async"
                               [configuration]="configuration$ | async"
                               [disableHeader]="!searchEnabled"
                               [context]="context"></ds-search-results>
        </div>
    </div>
</ds-page-with-sidebar>

<ng-template #sidebarContent>
    <ds-search-sidebar id="search-sidebar" *ngIf="!(isXsOrSm$ | async)"
                       [resultCount]="(resultsRD$ | async)?.payload?.totalElements"
                       [inPlaceSearch]="inPlaceSearch"></ds-search-sidebar>
    <ds-search-sidebar id="search-sidebar-sm" *ngIf="(isXsOrSm$ | async)"
                       [resultCount]="(resultsRD$ | async)?.payload.totalElements"
                       (toggleSidebar)="closeSidebar()"
    >
    </ds-search-sidebar>
</ng-template>

<ng-template #searchForm>
    <ds-search-form *ngIf="searchEnabled" id="search-form"
                    [query]="(searchOptions$ | async)?.query"
                    [scope]="(searchOptions$ | async)?.scope"
                    [currentUrl]="searchLink"
                    [scopes]="(scopeListRD$ | async)"
                    [inPlaceSearch]="inPlaceSearch"
                    [searchPlaceholder]="'search.search-form.placeholder' | translate">
    </ds-search-form>
    <div class="row mb-3 mb-md-1">
        <div class="labels col-sm-9 offset-sm-3">
            <ds-search-labels *ngIf="searchEnabled" [inPlaceSearch]="inPlaceSearch"></ds-search-labels>
        </div>
    </div>
</ng-template>
