<div class="container" *ngVar="(itemRD$ | async) as itemRD">
  <div class="item-page" *ngIf="itemRD?.hasSucceeded" @fadeInOut>
    <div *ngIf="itemRD?.payload as item">
      <ds-item-alerts [item]="item"></ds-item-alerts>
      <ds-item-versions-notice [item]="item"></ds-item-versions-notice>
      <ds-view-tracker [object]="item"></ds-view-tracker>
      <div class="d-flex flex-row">
        <ds-item-page-title-field class="mr-auto" [item]="item"></ds-item-page-title-field>
        <div class="pl-2 d-flex">
          <ds-atmire-item-export-dropdown class="mr-1" [item]="item" [compact]="true"></ds-atmire-item-export-dropdown>
          <ds-dso-page-edit-button [pageRoute]="itemPageRoute$ | async" [dso]="item" [tooltipMsg]="'item.page.edit'"></ds-dso-page-edit-button>
        </div>
      </div>
      <div class="simple-view-link my-3">
        <a class="btn btn-outline-primary" [routerLink]="[(itemPageRoute$ | async)]">
          {{"item.page.link.simple" | translate}}
        </a>
      </div>
      <table class="table table-responsive table-striped">
        <tbody>
          <ng-container *ngFor="let mdEntry of (metadata$ | async) | keyvalue">
            <tr *ngFor="let mdValue of mdEntry.value">
              <td>{{mdEntry.key}}</td>
              <td>{{mdValue.value}}</td>
              <td>{{mdValue.language}}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
      <ds-item-page-full-file-section [item]="item"></ds-item-page-full-file-section>
      <ds-item-page-collections [item]="item"></ds-item-page-collections>
      <ds-item-versions class="mt-2" [item]="item"></ds-item-versions>
    </div>
  </div>
  <ds-error *ngIf="itemRD?.hasFailed" message="{{'error.item' | translate}}"></ds-error>
  <ds-loading *ngIf="itemRD?.isLoading" message="{{'loading.item' | translate}}"></ds-loading>
</div>
