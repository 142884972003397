import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AtmireSelectiveExportDropdownComponent } from './atmire-export-dropdown/atmire-discovery-export-dropdown/atmire-selective-export-dropdown.component';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AtmireItemExportDropdownComponent } from './atmire-export-dropdown/atmire-item-export-dropdown/atmire-item-export-dropdown.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    NgbModule,
  ],
  declarations: [
    AtmireSelectiveExportDropdownComponent,
    AtmireItemExportDropdownComponent,
  ],
  exports: [
    AtmireSelectiveExportDropdownComponent,
    AtmireItemExportDropdownComponent,
  ]
})
export class AtmireSelectiveExportSharedModule {
}
