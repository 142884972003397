import {Component} from '@angular/core';
import { ItemComponent } from '../../../../+item-page/simple/item-types/shared/item.component';
import { ViewMode } from '../../../../core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../shared/object-collection/shared/listable-object/listable-object.decorator';
import {MetadataValue} from "../../../../core/shared/metadata.models";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {BitstreamDataService} from "../../../../core/data/bitstream-data.service";

@listableObjectComponent('Exhibit', ViewMode.StandalonePage)
@Component({
  selector: 'ds-exhibit',
  styleUrls: ['./exhibit.component.scss'],
  templateUrl: './exhibit.component.html'
})
/**
 * The component for displaying metadata and relations of an item of the type Exhibit
 */
export class ExhibitComponent extends ItemComponent {
  public uuid: string;

  public fields = ['dc.title', 'dc.description', 'dc.date.available'];

  constructor(protected bitstreamDataService: BitstreamDataService, protected activeModal: NgbActiveModal) {
    super(bitstreamDataService);
  }

  close() {
    this.activeModal.close();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.uuid = this.object.uuid;
  }

}
