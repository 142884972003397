import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { RemoteData } from '../../core/data/remote-data';
import { DSpaceObject } from '../../core/shared/dspace-object.model';

@Component({
  selector: 'avs-default-config',
  templateUrl: './default-avs-config.component.html',
})
/**
 * Avs config forms for DSpace objects
 */
export class DefaultAvsConfigComponent<TDomain extends DSpaceObject> implements OnInit {

  /**
   * The initial dspace object
   */
  public dsoRD$: Observable<RemoteData<TDomain>>;

  /**
   * The most recent time at which the form has been submitted.
   */
  formSubmissionTime: Date;

  public constructor(
    protected route: ActivatedRoute,
  ) {
  }

  /**
   * Setup the dspace object based on the routing
   */
  ngOnInit(): void {
    this.dsoRD$ = this.route.parent.data.pipe(first(), map((data) => data.dso || data.item));
  }

  /**
   * Calling this method will trigger the submission of the avs config forms
   */
  onSubmit() {
    this.formSubmissionTime = new Date();
  }
}
