<div>
  <div class="modal-header">{{'dso-selector.'+ action + '.' + objectType.toString().toLowerCase() + '.head' | translate}}
    <button type="button" class="close" (click)="close()" aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div style="padding: 5px">
    <p> To review the Version History for specific Item (even if that Item is deleted) paste the uuid in the text field and hit return. Otherwise, you may search for the Item using title or other metadata present and then select it from the results below:</p>
  </div>
  <div class="modal-body">
    <ds-dso-selector [qualifier]="qualifier" [currentDSOId]="dsoRD?.payload.uuid ? 'search.resourceid:' + dsoRD?.payload.uuid : null" [types]="selectorTypes" (onSelect)="selectObject($event)"></ds-dso-selector>
  </div>
</div>
