import { Component } from '@angular/core';
import {DSONameService} from "../../../../../core/breadcrumbs/dso-name.service";
import { SearchResultListElementComponent } from '../../../../../shared/object-list/search-result-list-element/search-result-list-element.component';
import { ItemSearchResult } from '../../../../../shared/object-collection/shared/item-search-result.model';
import { Item } from '../../../../../core/shared/item.model';
import { listableObjectComponent } from '../../../../../shared/object-collection/shared/listable-object/listable-object.decorator';
import { ViewMode } from '../../../../../core/shared/view-mode.model';
import { Observable } from 'rxjs/internal/Observable';
import { Bitstream } from '../../../../../core/shared/bitstream.model';
import { getFirstSucceededRemoteDataPayload } from '../../../../../core/shared/operators';
import { BitstreamDataService } from '../../../../../core/data/bitstream-data.service';
import { TruncatableService } from '../../../../../shared/truncatable/truncatable.service';

@listableObjectComponent('ContentResourceSearchResult', ViewMode.ListElement)
@Component({
  selector: 'ds-content-resource-search-result-list-element',
  styleUrls: ['./content-resource-search-result-list-element.component.scss'],
  templateUrl: './content-resource-search-result-list-element.component.html'
})
/**
 * The component for displaying a list element for an item search result of the type ContentResource
 */
export class ContentResourceSearchResultListElementComponent extends SearchResultListElementComponent<ItemSearchResult, Item> {

  constructor(protected bitstreamDataService: BitstreamDataService,
              protected truncatableService: TruncatableService,
              protected dsoNameService: DSONameService) {
    super(truncatableService, dsoNameService);
  }

  getThumbnail(dso: Item): Observable<Bitstream> {
    return this.bitstreamDataService.getThumbnailFor(dso).pipe(
      getFirstSucceededRemoteDataPayload()
    );
  }
}
