import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { SearchOptions } from '../../../../shared/search/search-options.model';
import { AtmireSelectiveExportOption } from '../../../models/atmire-selective-export-option.model';
import {
  invokeItemExportScriptBySearchConfig
} from '../../../atmire-selective-export.util';
import { AbstractAtmireExportDropdownComponent } from '../abstract-atmire-export-dropdown.component';
import { RemoteData } from '../../../../core/data/remote-data';
import { Process } from '../../../../process-page/processes/process.model';

@Component({
  selector: 'ds-atmire-selective-export-dropdown',
  templateUrl: './atmire-selective-export-dropdown.component.html',
  styleUrls: ['../abstract-atmire-export-dropdown.component.scss']
})
/**
 * Component displaying a dropdown menu, listing export options for a discovery search
 */
export class AtmireSelectiveExportDropdownComponent extends AbstractAtmireExportDropdownComponent {
  /**
   * The total number of results for the current search options
   */
  @Input() totalResults = -1;

  /**
   * The current configuration of the search
   */
  @Input() searchConfig: SearchOptions;

  /**
   * THe prefix to use for i18n messages
   */
  @Input() msgPrefix = 'discovery';

  /**
   * Export items using the current discovery arguments and selected export type
   * @param exportOption  The export type to export items to
   */
  invokeScript(exportOption: AtmireSelectiveExportOption): Observable<RemoteData<Process>> {
    return invokeItemExportScriptBySearchConfig(this.authService, this.scriptDataService, exportOption, this.searchConfig);
  }

}
