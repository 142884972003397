import { Component } from '@angular/core';
import { ItemComponent } from '../../../../+item-page/simple/item-types/shared/item.component';
import { ViewMode } from '../../../../core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../shared/object-collection/shared/listable-object/listable-object.decorator';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {BitstreamDataService} from "../../../../core/data/bitstream-data.service";
import {MetadataValue} from "../../../../core/shared/metadata.models";

@listableObjectComponent('ContentResource', ViewMode.StandalonePage)
@Component({
  selector: 'ds-content-resource',
  styleUrls: ['./content-resource.component.scss'],
  templateUrl: './content-resource.component.html'
})
/**
 * The component for displaying metadata and relations of an item of the type ContentResource
 */
export class ContentResourceComponent extends ItemComponent {

  public uuid: string;

  metadata:Map<string, MetadataValue> = new Map<string, MetadataValue>();

  constructor(protected bitstreamDataService: BitstreamDataService, protected activeModal: NgbActiveModal) {
    super(bitstreamDataService);
  }


  ngOnInit(): void {
    super.ngOnInit();
    this.uuid = this.object.uuid;
    this.popFieldMap();
  }

  popFieldMap() {
    let fields = ['dc.title', 'dc.title.alternative', 'dc.creator', 'dc.contributor', 'dc.publisher', 'dc.date.created',
      'dc.date.issued', 'dc.date.sort', 'dc.description', 'dc.format.extent', 'dc.subject', 'dc.coverage.spatial',
      'dc.subject.personOrCorporation', 'dc.type.genre', 'dc.type', 'dc.format', 'dc.language.iso',
      'dc.language', 'dc.description.notes', 'dc.identifier.access', 'dc.identifier.digital',
      'dc.source', 'dc.coverage.latitude', 'dc.coverage.longitude'];
    for (let i=0; i < fields.length; i++) {
      let metadatavalue:MetadataValue[] = this.object.allMetadata([fields[i]]);
      if (metadatavalue != null && metadatavalue.length == 1) {
        this.metadata.set(fields[i], metadatavalue[0]);
      }
    }
  }
}
