import {autoserialize, deserialize } from 'cerialize';
import { ListableObject } from '../../shared/object-collection/shared/listable-object.model';
import {link, typedObject} from '../cache/builders/build-decorators';
import {CacheableObject} from '../cache/object-cache.reducer';
import {BatchObject} from "./batch-object.model";
import {BATCH_OBJECT} from "./batch-object.resource-type";
import { GenericConstructor } from './generic-constructor';
import { HALLink } from './hal-link.model';
import {excludeFromEquals} from '../utilities/equals.decorators';
import {ResourceType} from './resource-type';
import {RTS_STATUS_OBJECT} from './rts-status-object.resource-type';
import {RTS_VERSION_OBJECT} from "./rts-version-object.resource-type"
import {Observable} from "rxjs";
import {RemoteData} from "../data/remote-data";
import {RTSVersionObject} from "./rts-version-object-model";
import {PaginatedList} from "../data/paginated-list.model";

/**
 * An abstract model class for a Batch Object.
 */
@typedObject
export class RTSStatusObject extends ListableObject implements CacheableObject {

  static type = RTS_STATUS_OBJECT;
  /**
   * A string representing the RTSStatusObject
   */
  @excludeFromEquals
  @autoserialize
  type: ResourceType;
  @autoserialize
  public id;
  @excludeFromEquals
  @autoserialize
  public dsoType: string;
  @autoserialize
  public existsInRTS: boolean;
  @autoserialize
  public existsInRepository: boolean;
  @autoserialize
  public lastModifiedDate: string;
  @autoserialize
  public restoring: boolean;
  @autoserialize
  public dsoId: string;
  @deserialize
  _links: {
    self: HALLink;
    versions: HALLink;
    restoreBatch: HALLink;
  };

  @link(RTS_VERSION_OBJECT, true)
  versions?: Observable<RemoteData<PaginatedList<RTSVersionObject>>>;

  @link(BATCH_OBJECT)
  restoreBatch?: Observable<RemoteData<BatchObject>>;

  /**
   * A shorthand to get this batch's self link
   */
  get self(): string {
    return this._links.self.href;
  }

  /**
   * A shorthand to set this DSpaceObject's self link
   */
  set self(v: string) {
    this._links.self = {
      href: v
    };
  }

  /**
   * Method that returns as which type of object this object should be rendered
   */
  getRenderTypes(): Array<string | GenericConstructor<ListableObject>> {
    return [this.constructor as GenericConstructor<ListableObject>];
  }
}
