<div>
  <div class="modal-header" >
    <h4 class="modal-title" id="modal-basic-title">IIIF Preview</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <ds-mirador
      class="mirador-container"
      [uuid]="this.uuid">
    </ds-mirador>
    <ds-item-page-file-section [item]="this.item"></ds-item-page-file-section>
  </div>
  <div class="modal-footer">
  </div>
</div>
