import { Injectable } from '@angular/core';
import * as uuidv4 from 'uuid/v4';

@Injectable()
export class UUIDService {
  generate(): string {
    return uuidv4();
  }

  isValid(value: string) {
    const regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    if (value.match(regex) != null) {
      return true;
    }
    return false;
  }
}
