import {autoserialize, autoserializeAs, deserialize} from 'cerialize';
import { ListableObject } from '../../shared/object-collection/shared/listable-object.model';
import {link, typedObject} from '../cache/builders/build-decorators';
import {CacheableObject} from '../cache/object-cache.reducer';
import { GenericConstructor } from './generic-constructor';
import { HALLink } from './hal-link.model';
import {BATCH_OBJECT} from './batch-object.resource-type';
import {excludeFromEquals} from '../utilities/equals.decorators';
import {ResourceType} from './resource-type';
import {Observable} from 'rxjs';
import {RemoteData} from '../data/remote-data';
import {EPERSON} from '../eperson/models/eperson.resource-type';
import {EPerson} from '../eperson/models/eperson.model';


/**
 * An abstract model class for a Batch Object.
 */
@typedObject
export class BatchObject extends ListableObject implements CacheableObject {

  static type = BATCH_OBJECT;
  /**
   * A string representing the BatchObject
   */
  @excludeFromEquals
  @autoserialize
  type: ResourceType;
  @autoserialize
  id;
  @autoserializeAs(String)
  person_id: string;
  @autoserialize
  batchType: string;
  @autoserializeAs(String)
  public created: string;
  @autoserialize
  public phase: string;
  @autoserialize
  public state: string;
  @autoserialize
  public position;
  @autoserialize
  public max_position;
  @autoserialize
  public options: Object;
  @autoserialize
  public allowedActions: string[];
  @autoserialize
  public file: any;
  @deserialize
  _links: {
    self: HALLink;
    eperson: HALLink;
  };

  @link(EPERSON)
  eperson?: Observable<RemoteData<EPerson>>;

  /**
   * A shorthand to get this batch's self link
   */
  get self(): string {
    return this._links.self.href;
  }

  /**
   * A shorthand to set this DSpaceObject's self link
   */
  set self(v: string) {
    this._links.self = {
      href: v
    };
  }

  /**
   * Method that returns as which type of object this object should be rendered
   */
  getRenderTypes(): Array<string | GenericConstructor<ListableObject>> {
    return [this.constructor as GenericConstructor<ListableObject>];
  }
}

export interface BatchOptions {
  email?: BatchEmail;
  autoAction?: BatchAutoAction;
  type?: any;
}

export interface BatchEmail {
  onFailure?: Object;
  onSuccess?: any;
}

export interface BatchAutoAction {
  Scan?: any;
  Expand?: any;
  Validate?: any;
  Import?: any;
  Load?: any;
  Derive?: any;
  Submit?: any;
}

