import {Component, Injector, Input, OnDestroy, OnInit} from '@angular/core';
import { Router } from '@angular/router';

import {BehaviorSubject, Observable} from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

import { WorkspaceItem } from '../../../core/submission/models/workspaceitem.model';
import { MyDSpaceActionsComponent } from '../mydspace-actions';
import { WorkspaceitemDataService } from '../../../core/submission/workspaceitem-data.service';
import { NotificationsService } from '../../notifications/notifications.service';
import { RequestService } from '../../../core/data/request.service';
import { SearchService } from '../../../core/shared/search/search.service';
import { getFirstCompletedRemoteData } from '../../../core/shared/operators';
import { RemoteData } from '../../../core/data/remote-data';
import { NoContent } from '../../../core/shared/NoContent.model';
import { Item } from '../../../core/shared/item.model';
import { PreviewModalComponent } from '../../../+my-dspace-page/preview/preview-modal.component';

/**
 * This component represents actions related to WorkspaceItem object.
 */
@Component({
  selector: 'ds-workspaceitem-actions',
  styleUrls: ['./workspaceitem-actions.component.scss'],
  templateUrl: './workspaceitem-actions.component.html',
})
export class WorkspaceitemActionsComponent extends MyDSpaceActionsComponent<WorkspaceItem, WorkspaceitemDataService> implements OnInit {

  /**
   * The workspaceitem object
   */
  @Input() object: WorkspaceItem;

  batchID$ = new BehaviorSubject<number>(-1);
  type: string = "";
  itemRD$: Observable<RemoteData<Item>> = null;
  itemUUID: string = "";
  item: Item;

  /**
   * A boolean representing if a delete operation is pending
   * @type {BehaviorSubject<boolean>}
   */
  public processingDelete$ = new BehaviorSubject<boolean>(false);

  /**
   * Initialize instance variables
   *
   * @param {Injector} injector
   * @param {Router} router
   * @param {NgbModal} modalService
   * @param {NotificationsService} notificationsService
   * @param {TranslateService} translate
   * @param {SearchService} searchService
   * @param {RequestService} requestService
   */
  constructor(protected injector: Injector,
              protected router: Router,
              protected modalService: NgbModal,
              protected notificationsService: NotificationsService,
              protected translate: TranslateService,
              protected searchService: SearchService,
              protected requestService: RequestService) {
    super(WorkspaceItem.type, injector, router, notificationsService, translate, searchService, requestService);
  }

  /**
   * Delete the target workspaceitem object
   */
  public confirmDiscard(content) {
    this.modalService.open(content).result.then(
      (result) => {
        if (result === 'ok') {
          this.processingDelete$.next(true);
          this.objectDataService.delete(this.object.id)
            .pipe(getFirstCompletedRemoteData())
            .subscribe((response: RemoteData<NoContent>) => {
              this.processingDelete$.next(false);
              this.handleActionResponse(response.hasSucceeded);
            });
        }
      }
    );
  }

  /**
   * Init the target object
   *
   * @param {WorkspaceItem} object
   */
  initObjects(object: WorkspaceItem) {
    this.object = object;
  }

  ngOnInit(): void {
    if (this.object.item != null) {
      if (!(this.object.item instanceof Item)) {
        this.itemRD$ = this.object.item;
        this.itemRD$.subscribe((rd) =>{
          if (rd.payload != null) {
            if (rd.payload.metadata != null) {
              if (rd.payload.metadata["dc.identifier.batch"] != null) {
                this.batchID$.next(+rd.payload.metadata["dc.identifier.batch"][0].value);
              }
              if (rd.payload.metadata["dspace.entity.type"] != null) {
                this.type = rd.payload.metadata["dspace.entity.type"][0].value;
              }
              this.itemUUID = rd.payload.uuid;
              this.item = rd.payload;
            }
          }
          });
      }
    }
  }

  isNumber(value) {
    return Number.isNaN(value);
  }

  open(uuid, item) {
    const activeModal = this.modalService.open(PreviewModalComponent, { size: 'xl' });
    activeModal.componentInstance.uuid = uuid;
    activeModal.componentInstance.item = item;
  }

}
