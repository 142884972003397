import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { dataService } from '../../core/cache/builders/build-decorators';
import { RemoteDataBuildService } from '../../core/cache/builders/remote-data-build.service';
import { ObjectCacheService } from '../../core/cache/object-cache.service';
import { CoreState } from '../../core/core.reducers';
import { DataService } from '../../core/data/data.service';
import { DefaultChangeAnalyzer } from '../../core/data/default-change-analyzer.service';
import { RemoteData } from '../../core/data/remote-data';
import { RequestService } from '../../core/data/request.service';
import { HALEndpointService } from '../../core/shared/hal-endpoint.service';
import { NotificationsService } from '../../shared/notifications/notifications.service';
import { FollowLinkConfig } from '../../shared/utils/follow-link-config.model';
import { AvsConfig } from './avs-config.model';
import { AVS_CONFIG } from './avs-config.resource-type';

/* tslint:disable:max-classes-per-file */

/**
 * DataService Implementation
 */
class DataServiceImpl extends DataService<AvsConfig> {
  protected linkPath: 'atmireAvsConfig';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<AvsConfig>) {
    super();
  }

}

/**
 * Service to create, update and get the AVS Settings
 */
@Injectable({providedIn:'root'})
@dataService(AVS_CONFIG)
export class AvsConfigService {
  /**
   * A private DataService instance to delegate specific methods to.
   */
  private dataService: DataServiceImpl;

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<AvsConfig>,
  ) {
    this.dataService = new DataServiceImpl(requestService, rdbService, store, objectCache, halService, notificationsService, http, comparator);
  }

  /**
   * Update or create the AVS Settings
   *
   * @param object The AvsConfig to update or create
   */
  put(object: AvsConfig) {
    return this.dataService.put(object);
  }

  /**
   * Update or create the AVS Settings
   *
   * @param object The AvsConfig to update or create
   */
  create(object: AvsConfig) {
    return this.dataService.create(object);
  }

  /**
   * Returns an observable of {@link RemoteData} of an AvsConfig, based on an href, with a list of {@link FollowLinkConfig},
   * to automatically resolve {@link HALLink}s of the AvsConfig
   * @param href                        The url of AvsConfig we want to retrieve
   * @param useCachedVersionIfAvailable If this is true, the request will only be sent if there's
   *                                    no valid cached version. Defaults to true
   * @param reRequestOnStale            Whether or not the request should automatically be re-
   *                                    requested after the response becomes stale
   * @param linksToFollow               List of {@link FollowLinkConfig} that indicate which
   *                                    {@link HALLink}s should be automatically resolved
   */
  findByHref(href: string, useCachedVersionIfAvailable = true, reRequestOnStale = true, ...linksToFollow: Array<FollowLinkConfig<AvsConfig>>): Observable<RemoteData<AvsConfig>> {
    return this.dataService.findByHref(href, useCachedVersionIfAvailable, reRequestOnStale, ...linksToFollow);
  }

}
