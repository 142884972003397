import {Component, Inject, Input, OnInit} from '@angular/core';
import { SearchService } from '../../../core/shared/search/search.service';
import { SortDirection, SortOptions } from '../../../core/cache/models/sort-options.model';
import { ActivatedRoute, Router } from '@angular/router';
import { PaginatedSearchOptions } from '../paginated-search-options.model';
import { Observable } from 'rxjs';
import { SearchConfigurationService } from '../../../core/shared/search/search-configuration.service';
import { SEARCH_CONFIG_SERVICE } from '../../../+my-dspace-page/my-dspace-page.component';
import { PaginationService } from '../../../core/pagination/pagination.service';

@Component({
  selector: 'ds-search-settings',
  styleUrls: ['./search-settings.component.scss'],
  templateUrl: './search-settings.component.html'
})

/**
 * This component represents the part of the search sidebar that contains the general search settings.
 */
export class SearchSettingsComponent implements OnInit {
  /**
   * The configuration for the current paginated search results
   */
  searchOptions$: Observable<PaginatedSearchOptions>;

  /**
   * All sort options that are shown in the settings
   */
  searchOptionPossibilities = [
   new SortOptions('score', SortDirection.DESC),
   new SortOptions('dc.title', SortDirection.ASC),
   new SortOptions('dc.title', SortDirection.DESC),
   new SortOptions('dc.date.accessioned', SortDirection.ASC),
   new SortOptions('dc.date.accessioned', SortDirection.DESC),
   new SortOptions('dc.identifier.digital', SortDirection.ASC),
   new SortOptions('dc.identifier.digital', SortDirection.DESC),
   new SortOptions('dc.date.issued', SortDirection.ASC),
   new SortOptions('dc.date.issued', SortDirection.DESC),
   new SortOptions('dc.date.sort', SortDirection.ASC),
   new SortOptions('dc.date.sort', SortDirection.DESC)
  ];

  @Input()
  searchOptionPossibilitiesInput: any;

  constructor(private service: SearchService,
              private route: ActivatedRoute,
              private router: Router,
              private paginationService: PaginationService,
              @Inject(SEARCH_CONFIG_SERVICE) public searchConfigurationService: SearchConfigurationService) {
    if (this.searchOptionPossibilitiesInput != null) {
      this.searchOptionPossibilities = this.searchOptionPossibilitiesInput;
    }
  }

  /**
   * Initialize paginated search options
   */
  ngOnInit(): void {
    if (this.searchOptionPossibilitiesInput != null) {
      this.searchOptionPossibilities = this.searchOptionPossibilitiesInput;
    }
    this.searchOptions$ = this.searchConfigurationService.paginatedSearchOptions;
  }

  /**
   * Method to change the current sort field and direction
   * @param {Event} event Change event containing the sort direction and sort field
   */
  reloadOrder(event: Event) {
    const values = (event.target as HTMLInputElement).value.split(',');
    this.paginationService.updateRoute(this.searchConfigurationService.paginationID, {
      sortField: values[0],
      sortDirection: values[1] as SortDirection,
      page: 1
    });
  }
}
