<ng-container *ngVar="(this.mediaInfo | async) as mediaInfo">
  <div *ngIf="mediaInfo?.hasSucceeded ">
    <ng-container *ngIf="(mediaInfo.payload.audios.length + mediaInfo.payload.videos.length) > 0">
      <atmire-avs-player-playlist [media]="mediaInfo.payload"></atmire-avs-player-playlist>
    </ng-container>
  </div>
  <div *ngIf="mediaInfo?.hasFailed ">
    <p>avs request failed</p>
  </div>
  <div *ngIf="mediaInfo?.isLoading ">
    <p>loading</p>
  </div>
  <div *ngIf="mediaInfo?.isRequestPending ">
    <p>pending</p>
  </div>
</ng-container>

