import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import { ItemComponent } from '../../../../+item-page/simple/item-types/shared/item.component';
import { ViewMode } from '../../../../core/shared/view-mode.model';
import { listableObjectComponent } from '../../../../shared/object-collection/shared/listable-object/listable-object.decorator';
import {NgbActiveModal, NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {BitstreamDataService} from "../../../../core/data/bitstream-data.service";
import {MetadataValue} from "../../../../core/shared/metadata.models";
import {BehaviorSubject} from "rxjs";
import {Item} from "../../../../core/shared/item.model";
import {Observable} from "rxjs/internal/Observable";
import {RemoteData} from "../../../../core/data/remote-data";
import {PaginatedList} from "../../../../core/data/paginated-list.model";
import {Bundle} from "../../../../core/shared/bundle.model";

@listableObjectComponent('CompoundItem', ViewMode.StandalonePage)
@Component({
  selector: 'ds-compound-item',
  styleUrls: ['./compound-item.component.scss'],
  templateUrl: './compound-item.component.html',
  encapsulation: ViewEncapsulation.None
})
/**
 * The component for displaying metadata and relations of an item of the type CompoundItem
 */
export class CompoundItemComponent extends ItemComponent implements OnInit {

  public uuid: string;

  hasManifest$: BehaviorSubject<boolean> = new BehaviorSubject<boolean> (false);

  metadata:Map<string, MetadataValue> = new Map<string, MetadataValue>();

  bundles$: Observable<RemoteData<PaginatedList<Bundle>>> = null

  constructor(private modalService: NgbModal, protected bitstreamDataService: BitstreamDataService, protected activeModal: NgbActiveModal) {
    super(bitstreamDataService);
  }

  close() {
    this.activeModal.close();
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.uuid = this.object.uuid;
    this.popFieldMap();
    this.hasManifest();
  }

  popFieldMap() {
    let fields = ['dc.title', 'dc.title.alternative', 'dc.creator', 'dc.contributor', 'dc.publisher', 'dc.date.created', 'dc.date.issued',
      'dc.date.sort', 'dc.description', 'dc.format.extent', 'dc.subject',  'dc.coverage.spatial', 'dc.subject.personOrCorporation', 'dc.type.genre',
      'dc.type', 'dc.format', 'dc.language.iso',
      'dc.language', 'dc.description.notes', 'dc.identifier.ubcCallNumber', 'dc.identifier.access', 'dc.identifier.digital',
      'dc.relation.isPartOf', 'dc.relation.isPartOfSeries', 'dc.source', 'dc.date.available', 'dc.publisher.digital',
      'dc.rights', 'dc.rights.license', 'dc.relation.catalogueRecord', 'dc.coverage.latitude', 'dc.coverage.longitude',
      'dc.identifier.aipuuid', 'dc.identifier.doi'];
    for (let i=0; i < fields.length; i++) {
      let metadatavalue:MetadataValue[] = this.object.allMetadata([fields[i]]);
      if (metadatavalue != null && metadatavalue.length == 1) {
        this.metadata.set(fields[i], metadatavalue[0]);
      }
    }
  }

  hasManifest(){
    let item:Item = this.object;
    this.bundles$ = item.bundles;
    this.bundles$.subscribe((bundles) => {
      if (bundles.hasSucceeded) {
        for(let i=0; i < bundles.payload.page.length; i++){
          if(bundles.payload.page[i].name == "MANIFEST") {
            this.hasManifest$.next(true);
          }
        }
      }
    });
  }

  open(content) {
    this.modalService.open(content, {size: 'lg', windowClass: 'modal-xl IIIFModal'});
  }
}
