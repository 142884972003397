import { CacheableObject } from '../../core/cache/object-cache.reducer';
import { HALLink } from '../../core/shared/hal-link.model';
import { ResourceType } from '../../core/shared/resource-type';
import { autoserialize, deserialize } from 'cerialize';
import { typedObject } from '../../core/cache/builders/build-decorators';
import { AVS_CONFIG } from './avs-config.resource-type';
import { excludeFromEquals } from '../../core/utilities/equals.decorators';

/**
 * AVS Settings
 */
@typedObject
export class AvsConfig implements CacheableObject {
  static type = AVS_CONFIG;

  @autoserialize
  playerPermissions: string;

  @autoserialize
  playerType: string;

  @deserialize
  _links: { self: HALLink };

  @excludeFromEquals
  @autoserialize
  type: ResourceType;
}
