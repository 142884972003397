<div class="d-flex flex-row">
  <h2 class="item-page-title-field mr-auto">
    <ds-metadata-values [mdValues]="object?.allMetadata(['dc.title'])"></ds-metadata-values>
  </h2>
  <div class="pl-2">
    <ds-dso-page-edit-button [pageRoute]="itemPageRoute" [dso]="object" [tooltipMsg]="'item.page.edit'"></ds-dso-page-edit-button>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <atmire-avs-player [item]="object"></atmire-avs-player>
  </div>
</div>
<div class="row">
  <div class="col-xs-12 col-md-4">
    <ds-metadata-field-wrapper>
      <ds-thumbnail [thumbnail]="getThumbnail() | async"></ds-thumbnail>
    </ds-metadata-field-wrapper>
    <ds-item-page-file-section [item]="object"></ds-item-page-file-section>
  </div>
  <div class="col-xs-12 col-md-6">
    <ds-metadata-representation-list
      [parentItem]="object"
      [itemType]="'Person'"
      [metadataField]="'dc.contributor.author'"
      [label]="'relationships.isAuthorOf' | translate">
    </ds-metadata-representation-list>
    <ds-related-items
      [parentItem]="object"
      [relationType]="'isProjectOfPublication'"
      [label]="'relationships.isProjectOf' | translate">
    </ds-related-items>
    <div class="contentResourceExhibit">
      <ds-related-items
        [parentItem]="object"
        [relationType]="'itemOfResource'"
        [label]="'Exhibit'">
      </ds-related-items>
    </div>
    <div class="contentResourceItems">
      <ds-related-items
        [parentItem]="object"
        [relationType]="'itemOfResource'"
        [label]="'Parent Items'">
      </ds-related-items>
    </div>
  </div>
</div>
<div class="row pageContianer">
  <table class="table table-responsive table-striped">
    <tbody>
    <tr *ngFor="let key of metadata.keys()">
      <td>{{key}}</td>
      <td>{{metadata.get(key).value}}</td>
      <td>{{metadata.get(key).language}}</td>
    </tr>
    </tbody>
  </table>
  <div>
    <a class="btn btn-outline-primary" [routerLink]="['/items/' + object.id + '/full']">
      {{"item.page.link.full" | translate}}
    </a>
  </div>
</div>
