<ng-container *ngVar="(this.object.eperson | async) as ePersonRemoteData">
  <ng-container *ngIf="ePersonRemoteData?.payload as ePerson">
    <ng-template #content>
      <div class="modal-header" >
        <h4 class="modal-title" id="modal-basic-title">Confirm Delete</h4>
        <button type="button" class="close" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p> Are you sure you'd like to delete this Batch? </p>
      </div>
      <div class="modal-footer">
        <button type="button" aria-label="Close" (click)="close()">
          <span aria-hidden="true">Cancel</span>
        </button>
        <button type="button" aria-label="Confirm" (click)="confirmDelete(object.id)">
          <span aria-hidden="true">Confirm</span>
        </button>
      </div>
    </ng-template>
    <div *ngIf="object.id" class="row">
      <div class="col" style="font-weight: bold">{{object.id}}</div>
      <div class="col">{{object.created.split("T")[0]}}</div>
      <div class="col">{{object.batchType}}</div>
      <div class="col">{{object.phase}}</div>
      <div class="col">{{object.state}}</div>
      <div class="col"><p>{{ePerson.name}}</p></div>
      <div class="col align-items-center"><button class="btn btn-sm btn-primary mt-1" *ngFor="let action of object.allowedActions" (click)="doAction(action, object.id, content)">{{action}}</button></div>
      <div class="col"><ds-batch-list-element-files *ngIf="object.file" [batch]="object"></ds-batch-list-element-files></div>
    </div>
  </ng-container>
</ng-container>
