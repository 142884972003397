import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { ExhibitComponent } from './item-pages/exhibit/exhibit.component';
import { CompoundItemComponent } from './item-pages/compound-item/compound-item.component';
import { ContentResourceComponent } from './item-pages/content-resource/content-resource.component';
import {CompoundItemGridElementComponent} from './item-grid-elements/compound-item/compound-item-grid-element.component';
import {CompoundItemSearchResultGridElementComponent} from './item-grid-elements/search-result-grid-elements/compound-item/compound-item-search-result-grid-element.component';
import {CompoundItemListElementComponent} from './item-list-elements/compound-item/compound-item-list-element.component';
import {CompoundItemSearchResultListElementComponent} from './item-list-elements/search-result-list-elements/compound-item/compound-item-search-result-list-element.component';
import {ContentResourceGridElementComponent} from './item-grid-elements/content-resource/content-resource-grid-element.component';
import {ContentResourceListElementComponent} from './item-list-elements/content-resource/content-resource-list-element.component';
import {ContentResourceSearchResultListElementComponent} from './item-list-elements/search-result-list-elements/content-resource/content-resource-search-result-list-element.component';
import {ContentResourceSearchResultGridElementComponent} from './item-grid-elements/search-result-grid-elements/content-resource/content-resource-search-result-grid-element.component';
import {ExhibitGridElementComponent} from './item-grid-elements/exhibit/exhibit-grid-element.component';
import {ExhibitListElementComponent} from './item-list-elements/exhibit/exhibit-list-element.component';
import {ExhibitSearchResultGridElementComponent} from './item-grid-elements/search-result-grid-elements/exhibit/exhibit-search-result-grid-element.component';
import {ExhibitSearchResultListElementComponent} from './item-list-elements/search-result-list-elements/exhibit/exhibit-search-result-list-element.component';
import { AvsPlayerModule } from '../../avs-player/avs-player.module';

const ENTRY_COMPONENTS = [
// put only entry components that use custom decorator
  ExhibitComponent,
  CompoundItemComponent,
  CompoundItemGridElementComponent,
  CompoundItemListElementComponent,
  CompoundItemSearchResultGridElementComponent,
  CompoundItemSearchResultListElementComponent,
  ContentResourceGridElementComponent,
  ContentResourceListElementComponent,
  ContentResourceSearchResultGridElementComponent,
  ContentResourceSearchResultListElementComponent,
  ExhibitGridElementComponent,
  ExhibitListElementComponent,
  ExhibitSearchResultGridElementComponent,
  ExhibitSearchResultListElementComponent,
  ContentResourceComponent
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    AvsPlayerModule,
  ],
  declarations: [
    ...ENTRY_COMPONENTS,
  ]
})
export class UBCEntitiesModule {
  /**
   * NOTE: this method allows to resolve issue with components that using a custom decorator
   * which are not loaded during CSR otherwise
   */
  static withEntryComponents() {
    return {
      ngModule: UBCEntitiesModule,
      providers: ENTRY_COMPONENTS.map((component) => ({provide: component}))
    };
  }
}
