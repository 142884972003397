<div class="card">
  <a *ngIf="linkType != linkTypes.None" [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/collections/', dso.id]" class="card-img-top">
    <ds-grid-thumbnail [thumbnail]="(dso.logo | async)?.payload">
    </ds-grid-thumbnail>
  </a>
  <span *ngIf="linkType == linkTypes.None" class="card-img-top">
    <ds-grid-thumbnail [thumbnail]="(dso.logo | async)?.payload">
    </ds-grid-thumbnail>
  </span>
  <div class="card-body">
    <ds-type-badge *ngIf="showLabel" [object]="dso"></ds-type-badge>
    <h4 class="card-title">{{dso.name}}</h4>
    <p *ngIf="dso.shortDescription" class="card-text">{{dso.shortDescription}}</p>
    <div *ngIf="linkType != linkTypes.None" class="text-center">
      <a [target]="(linkType == linkTypes.ExternalLink) ? '_blank' : '_self'" rel="noopener noreferrer" [routerLink]="['/collections/',  dso.id]" class="lead btn btn-primary viewButton">View</a>
    </div>
  </div>
  <ng-content></ng-content>
</div>
