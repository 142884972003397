import {Component} from '@angular/core';

import { AbstractListableElementComponent } from '../../object-collection/shared/object-collection-element/abstract-listable-element.component';
import { ViewMode } from '../../../core/shared/view-mode.model';
import { listableObjectComponent } from '../../object-collection/shared/listable-object/listable-object.decorator';
import {BatchObject} from '../../../core/shared/batch-object.model';
import {BatchObjectService} from '../../../core/data/batch-object.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'ds-batch-list-element',
  styleUrls: ['./batch-list-element.component.scss'],
  templateUrl: './batch-list-element.component.html'
})
/**
 * Component representing list element for a batch
 */
@listableObjectComponent(BatchObject, ViewMode.ListElement)
export class BatchListElementComponent extends AbstractListableElementComponent<BatchObject> {

  constructor(private batchObjectService: BatchObjectService,
              private modalService: NgbModal) {
    super();
  }

  doAction(action, batchID, content) {
    if (action === 'Delete') {
      this.modalService.open(content);
    } else {
      this.batchObjectService.doAction(action, batchID);
    }
  }

  confirmDelete(batchID) {
    this.modalService.dismissAll();
    this.batchObjectService.doAction('Delete', batchID);
  }

  close() {
    this.modalService.dismissAll();
  }
}
