import {Injectable} from "@angular/core";
import {DataService} from "./data.service";
import {RTSStatusObject} from "../shared/rts-status-object-model";
import {HALEndpointService} from "../shared/hal-endpoint.service";
import {HttpClient} from "@angular/common/http";
import {NotificationsService} from "../../shared/notifications/notifications.service";
import {RemoteDataBuildService} from "../cache/builders/remote-data-build.service";
import {CoreState} from "../core.reducers";
import {Store} from "@ngrx/store";
import {RequestService} from "./request.service";
import {ObjectCacheService} from "../cache/object-cache.service";
import {DefaultChangeAnalyzer} from "./default-change-analyzer.service";
import {Observable} from "rxjs/internal/Observable";
import {switchMap} from "rxjs/operators";

/**
 * Service handling all RTS status objects requests
 */
@Injectable()
export class RTSStatusObjectService extends DataService<RTSStatusObject> {
  protected linkPath: string = 'rts-statuses';

  constructor(protected requestService: RequestService,
              protected rdbService: RemoteDataBuildService,
              protected store: Store<CoreState>,
              protected halService: HALEndpointService,
              protected objectCache: ObjectCacheService,
              protected notificationsService: NotificationsService,
              protected http: HttpClient,
              protected comparator: DefaultChangeAnalyzer<RTSStatusObject>) {
    super();
  }

  getBrowseEndpoint(options, linkPath?: string): Observable<string> {
    return this.halService.getEndpoint(this.linkPath);
  }

  getRTSStatusEndpoint(objectID): Observable<string> {
    return this.halService.getEndpoint(this.linkPath).pipe(
      switchMap((href) => this.halService.getEndpoint('rts-statuses', `${href}/${objectID}`))
    );
  }
}
