import {Component, Input, OnInit} from '@angular/core';
import {BatchObject} from '../../../../core/shared/batch-object.model';
import {BatchObjectService} from '../../../../core/data/batch-object.service';
import {Router} from '@angular/router';

@Component({
  selector: 'ds-batch-list-element-files',
  styleUrls: ['./batch-list-element-files.component.scss'],
  templateUrl: './batch-list-element-files.component.html'
})
/**
 * Component representing list element for a batch
 */
export class BatchListElementFilesComponent implements OnInit {

  @Input()
  public batch: BatchObject;

  arrayOfKeys: string[];

  keysWithPath: string[] = new Array<string>();

  keysWithPathInArray: string[] = new Array<string>();

  fileMapInArray: Map<string, string> = new Map<string, string>();

  nameMapForArray: Map<string, string> = new Map<string, string>();

  fileMap: Map<string, string> = new Map<string, string>();

  pathMap: Map<string, string> = new Map<string, string>();

  objectMap;

  constructor(private batchObjectService: BatchObjectService, private router: Router) {
  }

  ngOnInit(): void {
    this.objectMap = new Map(Object.entries(this.batch.file));
    this.arrayOfKeys = Object.keys(this.batch.file);
    for (const key of this.arrayOfKeys) {
      if (this.objectMap.get(key).path != null) {
        this.keysWithPath.push(key);
        this.fileMap.set(key, this.batch._links.self.href + '/file/' + this.objectMap.get(key).path);
      }
      if (this.objectMap.get(key)[0] != null && this.objectMap.get(key)[0].path != null) {
        for (let y = 0; y < this.objectMap.get(key).length; y++) {
          if (key !== 'input') {
            if (this.objectMap.get(key)[y].size > 0) {
              this.keysWithPathInArray.push(key + y);
              this.fileMapInArray.set(key + y, this.batch._links.self.href + '/file/' + this.objectMap.get(key)[y].path);
              this.pathMap.set(key + y, this.objectMap.get(key)[y].path);
              this.nameMapForArray.set(key + y, this.objectMap.get(key)[y].label);
            }
          }
        }
      }
    }
  }

  createExport(batchID, filePath) {
    this.batchObjectService.doCSVExport(batchID, filePath);
  }

  myDspaceLinkClick(batchId) {
    this.router.navigateByUrl('mydspace?configuration=workspace&f.batchId='+ batchId+'&page=1');
  }
}
