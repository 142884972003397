import {Component, ViewEncapsulation} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Item} from '../../core/shared/item.model';

@Component({
  selector: 'ds-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['preview-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PreviewModalComponent {

  uuid: string;
  item: Item;

  constructor(protected activeModal: NgbActiveModal) {
    console.log(this.uuid);
  }


  close() {
    this.activeModal.close();
  }

}












