<div class="outputs">
  <div class="table-responsive">
    <table id="batchOutputs" class="table table-striped table-hover table-bordered">
      <thead>
      <tr>
        <th scope="col">Phase</th>
        <th scope="col">Phase State</th>
        <th scope="col">Finish Date</th>
        <th scope="col">Outputs</th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let phase of phaseKeyArray">
        <td>{{phase}}</td>
        <td *ngIf="batch.phase == phase && batch.state == 'Waiting'">Waiting</td>
        <td *ngIf="batch.phase == phase && batch.state != 'Waiting' && batch.state != 'Completed'">Failed</td>
        <td *ngIf="batch.phase != phase || batch.state == 'Completed'">Completed</td>
        <td>
          <div *ngFor="let object of phaseFiles.get(phase)">
            <p *ngIf="object.label == 'Completed'">{{this.phaseCompletedMap.get(phase)}}</p>
          </div>
        </td>
        <td>
          <div *ngFor="let object of phaseFiles.get(phase)">
            <a *ngIf="object.label != 'Completed' && !isCsv(object.label )" href="{{this.batch._links.self.href + '/file/' + object.path}}" target="_blank">{{object.label}}</a>
            <a *ngIf="object.label != 'Completed' && isCsv(object.label )" href="{{this.batch._links.self.href + '/file/' + object.path}}" >{{object.label}}</a>
          </div>
        </td>
      </tr>
      <tr *ngIf="!doesCurrentPhaseHaveOutputs(batch.phase) && !isSaveResult(batch.phase)">
        <td>{{batch.phase}}</td>
        <td>Running</td>
        <td></td>
        <td>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</div>
