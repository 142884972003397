import { Injectable } from '@angular/core';
import { Item } from '../core/shared/item.model';
import { RequestService } from '../core/data/request.service';
import { HALEndpointService } from '../core/shared/hal-endpoint.service';
import { RemoteDataBuildService } from '../core/cache/builders/remote-data-build.service';
import { MediaInfo } from './media-info.model';
import { switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { RemoteData } from '../core/data/remote-data';
import { MEDIA_INFO } from './media-info.resource-type';
import { dataService } from '../core/cache/builders/build-decorators';
import { DataService } from '../core/data/data.service';
import { Store } from '@ngrx/store';
import { CoreState } from '../core/core.reducers';
import { ObjectCacheService } from '../core/cache/object-cache.service';
import { NotificationsService } from '../shared/notifications/notifications.service';
import { HttpClient } from '@angular/common/http';
import { DefaultChangeAnalyzer } from '../core/data/default-change-analyzer.service';

/* tslint:disable:max-classes-per-file */

/**
 * DataService implementation
 */
class DataServiceImpl extends DataService<MediaInfo> {
  protected linkPath: 'media';

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<MediaInfo>) {
    super();
  }

}

/**
 * Service to get the data for the media player
 */
@Injectable({providedIn: 'root'})
@dataService(MEDIA_INFO)
export class AvsService {
  /**
   * A private DataService instance to delegate specific methods to.
   */
  private dataService: DataServiceImpl;

  constructor(
    protected requestService: RequestService,
    protected rdbService: RemoteDataBuildService,
    protected store: Store<CoreState>,
    protected objectCache: ObjectCacheService,
    protected halService: HALEndpointService,
    protected notificationsService: NotificationsService,
    protected http: HttpClient,
    protected comparator: DefaultChangeAnalyzer<MediaInfo>,
  ) {
    this.dataService = new DataServiceImpl(requestService, rdbService, store, objectCache, halService, notificationsService, http, comparator);
  }

  /**
   * Returns an observable of {@link RemoteData} of an MediaInfo for an item
   * @param item The item for which to find the MediaInfo.
   */
  getMediaInfo(item: Item): Observable<RemoteData<MediaInfo>> {
      return this.halService.getEndpoint('video-streaming/media').pipe(
      switchMap((href) => this.dataService.findByHref(href + '?scope=' + item.uuid)),
    );
  }
}
